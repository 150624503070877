var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-label" }),
        _vm._v(_vm._s(_vm.$t("Fail Records")))
      ]),
      _c(
        "div",
        { staticClass: "searchArea" },
        [
          _c("v-text-field", {
            staticClass: "form-input",
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              label: "Update ID",
              placeholder: "Input the update ID",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchLabelList()
              }
            }
          }),
          _c("v-text-field", {
            staticClass: "form-input ml-3",
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              label: "Label ID",
              placeholder: "Input the label ID",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchLabelList()
              }
            }
          }),
          _c("v-select", {
            staticClass: "form-select ml-3",
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              label: "Fail reason",
              placeholder: "Choose fail reason",
              items: ["Template Error", "Outbound Error"],
              clearable: ""
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "btn type-search ml-3",
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.searchLabelList()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Search")))]
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "tbl-type01 mt-10",
        attrs: {
          headers: _vm.labelHeaders,
          items: _vm.labelItems,
          page: _vm.page,
          "hide-default-footer": true,
          "item-key": "id"
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "page-count": function($event) {
            _vm.pageCount = $event
          }
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c("v-btn", { staticClass: "btn", attrs: { text: "" } }, [
              _vm._v(_vm._s(_vm.$t("Export")))
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "pageInfo" }, [
              _vm._v(
                "1 " +
                  _vm._s(_vm.$t("to")) +
                  " 7, 7 " +
                  _vm._s(_vm.$t("in total"))
              )
            ]),
            _c("v-pagination", {
              staticClass: "paging",
              attrs: { length: _vm.pageCount, color: "#2f3b4c" },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }